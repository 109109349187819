import { Link } from "react-router-dom";
import HGVLogoSVG from "./assets/hgv.svg";
import AccountButton from "./AccountButton";
import "./Header.css";

const Header = () => (
  <header className="header">
    <div className="logo">
      <img src={HGVLogoSVG} />
      <Link to="/">Konto</Link>
    </div>
    <AccountButton />
  </header>
);

export default Header;
