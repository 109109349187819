import {
  CustomLanguageFormats,
  UserSettingsFlowType,
  locales,
} from "@ory/elements";

export const i18n = {
  getUserSettingsFlowTitle: (flowType: UserSettingsFlowType) => {
    switch (flowType) {
      case "profile":
        return "Profilinformationen";
      case "password":
        return "Passwort ändern";
      default:
        return undefined;
    }
  },
  getUserSettingsFlowDescription: (flowType: UserSettingsFlowType) => {
    switch (flowType) {
      case "profile":
        return "Aktualisieren Sie Ihren Namen und Ihre E-Mail-Adresse.";
      case "password":
        return "Legen Sie ein sicheres Passwort fest, welches Sie nicht für andere Konten verwenden.";
      default:
        return undefined;
    }
  },
  continue: "Weiter",
  welcome: "Willkommen",
  taxCode: "Steuernummer",
  customTranslations: {
    ...locales,
    de: {
      ...locales.de,
      "identities.messages.1060001":
        "Sie haben Ihr Konto erfolgreich wiederhergestellt. Bitte vergeben Sie jetzt ein neues Passwort.",
      "identities.messages.1070004": "E-Mail-Adresse",
      "identities.messages.4000006":
        "Die bereitgestellten Anmeldedaten sind ungültig. Überprüfen Sie E-Mail-Adresse und Passwort auf Rechtschreibfehler.",
    },
  } as CustomLanguageFormats,
};
