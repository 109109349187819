import classNames from "classnames";
import { useEffect, useState } from "react";
import "./LoadingSpinner.css";

interface Props {
  delayed?: number;
  size?: "small" | "default";
}

const LoadingSpinner = ({ delayed = 125, size = "default" }: Props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delayed, [delayed]);
    return () => clearTimeout(timeout);
  });

  return (
    <>
      {show && (
        <div
          className={classNames("loader", {
            ["small"]: size === "small",
          })}
          aria-label="Loading …"
          role="progressbar"
        >
          <div className="track" />
          <div className="fills">
            <div className="fillMask1">
              <div className="fillSubMask1">
                <div className="fill" />
              </div>
            </div>
            <div className="fillMask2">
              <div className="fillSubMask2">
                <div className="fill" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingSpinner;
