import { CustomTranslations, IntlProvider, ThemeProvider } from "@ory/elements";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Error } from "./Error";
import { Login } from "./Login";
import { Recovery } from "./Recovery";
import { Registration } from "./Registration";
import { Settings } from "./Settings";
import { Verification } from "./Verification";
import { themes } from "./themes";
import { AuthProvider } from "./authContext";

import "@ory/elements/assets/normalize.css";
import "./index.css";
import "@fontsource/open-sans/latin-400.css";
import "@fontsource/open-sans/latin-600.css";
import "@fontsource/open-sans/latin-700.css";
// required styles for Ory Elements
import "@ory/elements/style.css";
import { i18n } from "./i18n";
import registerSentry from "./sentry";

registerSentry();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider themeOverrides={themes.hgv}>
        <IntlProvider<CustomTranslations>
          customTranslations={i18n.customTranslations}
          locale="de"
          defaultLocale="de"
        >
          <Routes>
            <Route path="/" element={<Settings />} />
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/recovery" element={<Recovery />} />
            <Route path="/error" element={<Error />} />
          </Routes>
        </IntlProvider>
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
);
