import { Message } from "@ory/elements";
import "./AccountSelect.css";

interface AccountSelectProps {
  identifier: string;
  onSelect: () => void;
}

const AccountSelect = ({ identifier, onSelect }: AccountSelectProps) => {
  return (
    <Message className="accountSelect" onClick={onSelect}>
      <svg
        aria-hidden="true"
        fill="currentColor"
        focusable="false"
        viewBox="0 0 24 24"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6.36 14.83c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6z"></path>
      </svg>
      {identifier}
      <svg
        aria-hidden="true"
        fill="currentColor"
        focusable="false"
        viewBox="0 0 24 24"
        xmlns="https://www.w3.org/2000/svg"
      >
        <polygon points="12,16.41 5.29,9.71 6.71,8.29 12,13.59 17.29,8.29 18.71,9.71"></polygon>
      </svg>
    </Message>
  );
};

export default AccountSelect;
