import { Theme } from "@ory/elements";

interface Themes {
  hgv: Partial<Theme>;
}

export const themes: Themes = {
  hgv: {
    fontFamily: "Open Sans",
    accent: {
      def: "hsl(206 5% 30%)",
      muted: "#313335",
      emphasis: "#313335",
      disabled: "#e0e0e0",
      subtle: "hsl(54 91% 60%)",
    },
    border: {
      def: "hsl(220 6% 90%)",
    },
  },
};
