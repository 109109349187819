import HGVLogoSVG from "./assets/hgv.svg";
import "./HGVLogo.css";

const HGVLogo = () => (
  <img
    src={HGVLogoSVG}
    className="hgvLogo"
  />
);

export default HGVLogo;
