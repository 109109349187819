import { Identity, Session } from "@ory/client";
import {
  createContext,
  PropsWithChildren,
  useState,
  useEffect,
  useContext,
} from "react";
import { sdk } from "./sdk";

export interface User {
  email: string;
  name?: string;
  profilePhoto?: string;
}

type AuthContextType = {
  session?: Session;
  user?: User;
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  logout: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>(undefined!);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [session, setSession] = useState<Session>();
  const [user, setUser] = useState<User>();

  const logout = async () => {
    const { data: flow } = await sdk.createBrowserLogoutFlow();
    await sdk.updateLogoutFlow({
      token: flow.logout_token,
    });
    setSession(undefined);
    setUser(undefined);
  };

  useEffect(() => {
    sdk.toSession().then(({ data }) => {
      setSession(data);
      setUser(getUserFromIdentity(data.identity));
    });
  }, []);

  const value = {
    session,
    user,
    setUser,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const isEmployee = (email: string) =>
  ["@hgv.it", "@hgj.it"].some((domain) => email.toLowerCase().includes(domain));

export const useAuth = () => {
  return useContext(AuthContext);
};

interface UserIdentity extends Identity {
  traits: {
    email: string;
    name: string;
  };
  metadata_public?: {
    profile_photo?: string;
  };
}

const isUserIdentity = (i: Identity): i is UserIdentity => {
  return [
    "e5cb916994e485d758d041541f12385b37d0f93967d612e0937f60b7b1566012c6ab13379e79a9b4b58fe41f1e675ee80d9e38a2f1660ce67ce981ac3db2a033",
  ].includes(i.schema_id);
};

const getUserFromUserIdentity = (i: UserIdentity): User => {
  return {
    email: i.traits.email,
    name: i.traits.name,
    profilePhoto: i.metadata_public?.profile_photo,
  };
};

interface EmailIdentity extends Identity {
  traits: {
    email: string;
  };
}

const isEmailIdentity = (i: Identity): i is EmailIdentity => {
  return i.schema_id === "preset://email";
};

const getUserFromEmailIdentity = (i: EmailIdentity): User => {
  return {
    email: i.traits.email,
  };
};

export const getUserFromIdentity = (identity?: Identity): User => {
  if (!identity) {
    throw new Error("no identity found");
  }

  if (isUserIdentity(identity)) return getUserFromUserIdentity(identity);
  else if (isEmailIdentity(identity)) return getUserFromEmailIdentity(identity);

  console.warn(`unhandled schema '${identity.schema_id}'`);

  return { email: identity.id };
};
