import { User, isEmployee, useAuth } from "./authContext";
import "./AccountButton.css";
import useToggle from "./hooks/useToggle";
import { useRef } from "react";
import useOnClickOutside from "./hooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";

const getInitialChar = (s: string): string => {
  return s.charAt(0).toUpperCase();
};

const getUserInitials = ({ name, email }: User) => {
  const splitted = (name ?? email).split(" ");
  const numNames = splitted.length;

  switch (numNames) {
    case 1:
      return getInitialChar(splitted[0]!);
    default:
      const first = splitted[0]!;
      const last = splitted[numNames - 1]!;
      return getInitialChar(first) + getInitialChar(last);
  }
};

const Avatar = (user: User) => {
  return (
    <div>
      {isEmployee(user.email) && user.profilePhoto ? (
        <img src={user.profilePhoto} className="avatar" />
      ) : (
        <div className="avatar">{getUserInitials(user)}</div>
      )}
    </div>
  );
};

const Profile = (user: User) => {
  return (
    <div className="user">
      <Avatar {...user} />
      <div>
        <div className="userName">{user.name}</div>
        <div className="userEmail">{user.email}</div>
      </div>
    </div>
  );
};

const AccountButton = () => {
  const ref = useRef<HTMLDivElement>(null);
  const menu = useToggle();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  useOnClickOutside(ref, menu.close);

  if (!user) {
    return null;
  }

  return (
    <div className="accountButtonWrapper" ref={ref}>
      <button className="accountButton" onClick={menu.toggle}>
        <Avatar {...user} />
      </button>
      {menu.isOpen && (
        <div className="accountMenu">
          <Profile {...user} />
          <hr className="separator" />
          <ul className="list">
            <li>
              <button
                className="menuItem"
                onClick={async () => {
                  await logout();
                  navigate("/login");
                }}
              >
                Abmelden
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccountButton;
