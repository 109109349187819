import { SettingsFlow } from "@ory/client";

export const disableUiFormFields = (flow: SettingsFlow): SettingsFlow => ({
  ...flow,
  ui: {
    ...flow.ui,
    nodes: flow.ui.nodes.map((node) => {
      return {
        ...node,
        attributes: {
          ...node.attributes,
          disabled: true,
        },
      };
    }),
  },
});
