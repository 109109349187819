import { SettingsFlow, UpdateSettingsFlowBody } from "@ory/client";
import { gridStyle, NodeMessages, UserSettingsFlowType } from "@ory/elements";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sdk, sdkError } from "./sdk";
import { i18n } from "./i18n";
import { UserSettingsCard } from "./UserSettingsCard";
import Page from "./Page";
import { disableUiFormFields } from "./helpers";
import { getUserFromIdentity, isEmployee, useAuth } from "./authContext";
import LoadingSpinner from "./LoadingSpinner";

export const Settings = () => {
  const [flow, setFlow] = useState<SettingsFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { session, user, setUser } = useAuth();

  const navigate = useNavigate();

  // Get the flow based on the flowId in the URL (.e.g redirect to this page after flow initialized)
  const getFlow = useCallback(
    (flowId: string) =>
      sdk
        // the flow data contains the form fields, error messages and csrf token
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch(sdkErrorHandler),
    []
  );

  // initialize the sdkError for generic handling of errors
  const sdkErrorHandler = sdkError(getFlow, setFlow, "/login", true);

  const createFlow = () => {
    sdk
      // create a new settings flow
      // the flow contains the form fields, error messages and csrf token
      // depending on the Ory Network project settings, the form fields returned may vary
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        // Update URI query params to include flow id
        setSearchParams({ ["flow"]: flow.id });
        // Set the flow data
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  // submit any of the settings form data to Ory
  const onSubmit = (body: UpdateSettingsFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/", { replace: true });

    sdk
      // submit the form data the user provided to Ory
      .updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body })
      .then(({ data: flow }) => {
        if (flow.return_to) {
          window.location.href = flow.return_to;
          return;
        }
        setFlow(flow);
      })
      .catch(sdkErrorHandler);
  };

  useEffect(() => {
    // we might redirect to this page after the flow is initialized, so we check for the flowId in the URL
    const flowId = searchParams.get("flow");
    // the flow already exists
    if (flowId) {
      getFlow(flowId).catch(createFlow); // if for some reason the flow has expired, we need to get a new one
      return;
    }
    createFlow();
  }, []);

  useEffect(() => {
    if (!flow || session) return;
    setUser(getUserFromIdentity(flow.identity));
  }, [flow]);

  // if the flow is not set, we show a loading indicator
  return flow && user ? (
    <Page
      title="Persönliche Daten"
      description="Informationen über mich und meine Einstellungen in HGV-Diensten."
    >
      <div className={gridStyle({ gap: 32 })}>
        <NodeMessages uiMessages={flow.ui.messages} />
        {/* here we simply map all of the settings flows we could have. These flows won't render if they aren't enabled inside your Ory Network project */}
        {(
          [
            "profile",
            "password",
            "totp",
            "webauthn",
            "lookup_secret",
          ] as UserSettingsFlowType[]
        ).map((flowType, index) => (
          // here we render the settings flow using Ory Elements
          <UserSettingsCard
            key={index}
            // we always need to pass the component the flow since it contains the form fields, error messages and csrf token
            flow={isEmployee(user.email) ? disableUiFormFields(flow) : flow}
            flowType={flowType}
            title={i18n.getUserSettingsFlowTitle(flowType)}
            description={i18n.getUserSettingsFlowDescription(flowType)}
            // include scripts for webauthn support
            includeScripts={true}
            // submit the form data the user provides to Ory
            onSubmit={({ body }) => onSubmit(body as UpdateSettingsFlowBody)}
          />
        ))}
      </div>
    </Page>
  ) : (
    <div className="status">
      <LoadingSpinner />
    </div>
  );
};
