import { PropsWithChildren } from "react";
import Header from "./Header";
import { Typography } from "@ory/elements";
import "./Page.css";

type Props = PropsWithChildren<{
  title: string;
  description?: string;
}>;

const Page = ({ title, description, children }: Props) => {
  return (
    <div className="page">
      <Header />
      <div className="pageHeader">
        <Typography size="headline26">{title}</Typography>
        {description && (
          <Typography type="regular" size="small">
            {description}
          </Typography>
        )}
      </div>
      <div className="pageContent">{children}</div>
    </div>
  );
};

export default Page;
