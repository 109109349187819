import { SettingsFlow } from "@ory/client";
import {
  UserAuthFormAdditionalProps,
  useScriptNodes,
  hasPassword,
  hasWebauthn,
  WebAuthnSettingsSection,
  hasLookupSecret,
  LookupSecretSettingsSection,
  hasOidc,
  OIDCSettingsSection,
  hasTotp,
  TOTPSettingsSection,
  UserAuthForm,
  UserSettingsFlowType,
} from "@ory/elements";
import Panel from "./Panel";
import { ProfileSettingsSection } from "./sections/ProfileSettingsSection";
import { PasswordSettingsSection } from "./sections/PasswordSettingsSection";

type UserSettingsCardProps = {
  flow: SettingsFlow;
  flowType: UserSettingsFlowType;
  title?: string;
  description?: string;
  includeScripts?: boolean;
  className?: string;
} & UserAuthFormAdditionalProps;

export const UserSettingsCard = ({
  flow,
  flowType,
  title,
  description,
  includeScripts,
  onSubmit,
  className,
}: UserSettingsCardProps): JSX.Element | null => {
  if (includeScripts) {
    useScriptNodes({ nodes: flow.ui.nodes });
  }

  let hasFlow = false;
  let $flow: JSX.Element | null = null;

  switch (flowType) {
    case "profile":
      hasFlow = true;
      $flow = (
        <ProfileSettingsSection
          flow={flow}
          title={title ?? "Profile Settings"}
          description={description}
        />
      );
      break;
    case "password":
      if (hasPassword(flow.ui.nodes)) {
        hasFlow = true;
        $flow = (
          <PasswordSettingsSection
            flow={flow}
            title={title ?? "Change Password"}
            description={description}
          />
        );
      }
      break;
    case "webauthn":
      if (hasWebauthn(flow.ui.nodes)) {
        hasFlow = true;
        $flow = <WebAuthnSettingsSection flow={flow} />;
      }
      break;
    case "lookup_secret":
      if (hasLookupSecret(flow.ui.nodes)) {
        hasFlow = true;
        $flow = <LookupSecretSettingsSection flow={flow} />;
      }
      break;
    case "oidc":
      if (hasOidc(flow.ui.nodes)) {
        hasFlow = true;
        $flow = <OIDCSettingsSection flow={flow} />;
      }
      break;
    case "totp":
      if (hasTotp(flow.ui.nodes)) {
        hasFlow = true;
        $flow = <TOTPSettingsSection flow={flow} />;
      }
      break;
    default:
      $flow = null;
  }

  return hasFlow ? (
    <Panel>
      <UserAuthForm
        flow={flow}
        onSubmit={onSubmit}
        className={className}
        data-testid={`${flowType}-settings-card`}
      >
        {$flow}
      </UserAuthForm>
    </Panel>
  ) : null;
};
