import { UiText } from "@ory/client";
import {
  Button,
  ButtonLink,
  Card,
  colorSprinkle,
  Divider,
  gridStyle,
  InputField,
  Message,
  NodeMessages,
  typographyStyle,
} from "@ory/elements";
import axios, { AxiosError } from "axios";
import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import HGVLogo from "./HGVLogo";
import { i18n } from "./i18n";

const isUiTextArray = (v: unknown): v is UiText[] =>
  Array.isArray(v) && v.every((i) => "id" in i && "text" in i && "type" in i);

interface RegistrationRequest {
  taxCode: string;
  email: string;
  password: string;
}

interface RegistrationResponse {
  verification_flow_id: string;
}

export const Registration = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [messages, setMessages] = useState<UiText[]>();

  const navigate = useNavigate();
  const intl = useIntl();

  const submitFlow = async (body: RegistrationRequest) => {
    setIsSubmitting(true);
    try {
      const resp = await axios.post<RegistrationResponse>(
        `${import.meta.env.VITE_IAM_API_URL}/v2/legal_representatives`,
        {
          tax_code: body.taxCode,
          email: body.email,
          password: body.password,
        }
      );
      navigate(`/verification?flow=${resp.data.verification_flow_id}`, {
        replace: true,
      });
    } catch (e) {
      if (e instanceof AxiosError && isUiTextArray(e.response?.data)) {
        setMessages(e.response.data);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Card
      className="userAuthCard"
      image={<HGVLogo />}
      heading={
        <h2 className={typographyStyle({ type: "regular", size: "small" })}>
          {intl.formatMessage({ id: "registration.title" })}
        </h2>
      }
    >
      <div className={gridStyle({ gap: 32 })}>
        <NodeMessages uiMessages={messages} />
        <Divider />
        <form
          onSubmit={(e) => {
            // Prevent multiple submits
            if (isSubmitting) return;

            e.preventDefault();
            const form = e.currentTarget;
            const formData = new FormData(form);

            submitFlow({
              taxCode: formData.get("tax_code") as string,
              email: formData.get("email") as string,
              password: formData.get("password") as string,
            });
          }}
        >
          <div className={gridStyle({ gap: 32 })}>
            <div className={gridStyle({ gap: 16 })}>
              <InputField name="tax_code" header={i18n.taxCode} required />
              <InputField
                type="email"
                name="email"
                header={intl.formatMessage({
                  id: "identities.messages.1070004",
                })}
                required
              />
              <InputField
                type="password"
                name="password"
                header={intl.formatMessage({
                  id: "settings.navigation-password",
                })}
                required
              />
            </div>
            <div className={gridStyle({ gap: 16 })}>
              <Button
                type="submit"
                header={
                  isSubmitting
                    ? "..."
                    : intl.formatMessage({ id: "login.registration-button" })
                }
                fullWidth
                disabled={isSubmitting}
              />
            </div>
          </div>
        </form>
        <Message className={colorSprinkle({ color: "foregroundMuted" })}>
          {intl.formatMessage({ id: "registration.login-label" })}&nbsp;
          <ButtonLink href="/login">
            {intl.formatMessage({ id: "registration.login-button" })}
          </ButtonLink>
        </Message>
      </div>
    </Card>
  );
};
